import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="contentholder-central">
    <p>To get in touch, please email us at <a href="mailto:hello@mugs-games.com">hello@mugs-games.com</a>.</p>
    <p>Follow us at <a rel="noreferrer" target="_blank" href="https://twitter.com/mugs_games">Twitter</a>, <a rel="noreferrer" target="_blank" href="https://www.instagram.com/mugs_games">Instagram</a>, <a rel="noreferrer" target="_blank" href="https://www.facebook.com/mugsgames">Facebook</a>.</p>
    <p>Or signup to our mailing list above or below.</p>
    </div>
  </Layout>
)

export default IndexPage
